import React, { useEffect, useState, useContext } from "react";
import styled from 'styled-components';
import { GoMegaphone } from "react-icons/go";
import { BiBookBookmark, BiExit, BiMessageDetail, BiUser, BiLogOut, BiLogOutCircle } from 'react-icons/bi';
import { IoBasketOutline, IoExtensionPuzzleOutline } from "react-icons/io5";
import { AiFillStar } from "react-icons/ai";
import { breakpoints } from '../../_helpers/breakpoints';
import { ContentContext,
         AuthContext } from '../../_context';
import { useHistory, useLocation } from 'react-router';
import { BsBox } from "react-icons/bs";
import { BsMegaphone, BsListUl } from "react-icons/bs";
import { PiBookOpenText } from "react-icons/pi";
import Drawer from '@mui/material/Drawer';
import { ImageRound } from '../../_elements';
import { path, defaultTo } from 'ramda';
import { LuFolderUp } from "react-icons/lu";
import { ReactComponent as MetaIconSVG } from '../../_assets/images/meta_icono.svg';
import { ReactComponent as BaristaIcon } from '../../_assets/images/baristas_icon.svg';

import { incentivoTiendas, bocas_seguridad_2024, dnis_seguridad_2024, show_baristas } from '../../_helpers/commons';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 99999;
  &.hide {
    transform: translate3d(-100vw, 0, 0);
  }
  &.show {
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
  }
  ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80vw;
    //height: 60vw;
    //background-color: pink;
    margin: 0;
    padding: 0;
    margin-left: 5vw;
    li{
      display: flex;
      flex-direction: row;
      align-items: center;
      //width: 30vw;
      margin: 0;
      padding: 0;
      margin-bottom: 2.5vw;
      list-style: none;
      p{
        display: flex;
        font-size: 5vw;
        margin: 0;
        margin-left: 2vw;
        font-weight: 400;
      }
      .ico{
        //width: 3.2vw;
        //background-color: lightblue;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 4.8vw;
      }
      
      &.bt_salir{
        .ico{
          font-size: 5.5vw;
        }
      }
    }
  }
`
const Logo = styled.div`
    //background-color: skyblue;
    cursor: pointer;
    max-width: 50vw;
    margin-top: 4vw;
    margin-left: 5vw;

    .image{
      display: flex;
      align-items: center;
      width: 50vw;
      img{
        width: 100%;
      }
    }

    @media ${breakpoints.xs} {
      width: 240px;
      .image{
        display: flex;
        align-items: center;
        width: 200px;
        img{
          width: 100%;
        }
      }
    }
    @media ${breakpoints.sm} {
      //width: 400px;
    }
    @media ${breakpoints.md} {
      //width: 450px;
    }
    @media ${breakpoints.lg} {
      width: 240px;
    }
`
const Divider = styled.div`
  display: flex;
  width: calc(100% - 6vw);
  height: 1px;
  margin-top: 4vw;
  margin-bottom: 4vw;
  margin-left: 3vw;
  background-color: #CCCCCC;
`
const DrawerStyled = styled(Drawer)`
    .inner_drawer{
        width: 300px!important;
        .user_drawer{
          margin-left: 25px;
          margin-top: 25px;
          .image_round{
            border: 1px solid #FFD200;
          }
          .user_info{
            margin-top: 10px;
            .name{
              margin: 0;
              font-size: 16px;
              font-weight: 600;
            }
            .eess{
              margin: 0;
              font-size: 13px;
            }
            .role{
              margin: 0;
              font-size: 13px;
              font-weight: 600;
              color: #D8010F;
            }
          }
        }
        ul{
          list-style: none;
          margin: 20px 0 30px 0;
          padding: 0;

          li{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 7px 25px 7px 25px;
            cursor: pointer;
            .ico{
              font-size: 20px;
              &.meta{
                width: 20px;
                height: 21px;
                svg{
                  fill: #212529;
                  vertical-align: top;
                }
              }
            }
            &.baristas{
              .icon{
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                svg{
                  fill: #212529;//#D8010F;
                }
              }
              p{
                margin: 0;
                padding: 0;
                font-weight: 500;
              }
            }
            .text{
              margin-left: 10px;
              font-size: 16px;
              font-weight: 500;
            }
            :hover{
              color: #D8010F;
              .ico{
                &.meta{
                  svg{
                    fill: #D8010F;
                  }
                }
              }
              &.baristas{
                .icon{
                  svg{
                    fill: #D8010F;
                  }
                }
              }
            }
            &.selected{
              color: #D8010F;
              .ico{
                &.meta{
                  svg{
                    fill: #D8010F;
                  }
                }
              }
              //background-color: #eaeaea!important;
            }

            &.bt_salir{
              width: calc(100% - 20px);
              margin-left: 10px;
              margin-top: 15px;
              padding-left: 15px;
              background-color: #D8010F;
              border: 1px solid #D8010F;
              .text{
                color: #FFFFFF;
              }
              .ico{
                color: #FFFFFF;
              }
              :hover{
                background-color: #FFFFFF;
                .text{
                  color: #D8010F;
                }
                .ico{
                  color: #D8010F;
                }
              }
            }

          }
        }
        .horizontal_line{
            width: calc(100% - 20px);
            margin-left: 10px;
            margin-top: 20px;
            margin-bottom: 30px;
            height: 2px;
            background-color: #000000;
        }
    }

    @media ${breakpoints.xs} {

    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {

    }
    @media ${breakpoints.xl} {

    }
    @media ${breakpoints.xxl} {

    }
    @media ${breakpoints.xxxl} {

    }
    @media ${breakpoints.xxxxl} {

    }

`

const MenuMobile = () => {

  const [ openDrawer, setOpenDrawer ] = useState(false);

  const { logout } = useContext(AuthContext);
  const { visibilityMenuMobile,
          setVisibilityMenuMobile,
          openModalContacto,
          setOpenModalContacto,
          openModalEmbajadores,
          setOpenModalEmbajadores,
          userData } = useContext(ContentContext);

  const history = useHistory();
  const location = useLocation();

  const handleShowMenu = (route) => {
    history.push(route);
    setVisibilityMenuMobile("hide");
  }

  const handleOpenContact = () => {
    setOpenModalContacto(!openModalContacto);
  }

  const handleOpenEmbajadores = () => {
    setOpenModalEmbajadores(!openModalEmbajadores);
  }

  const handleGoToBaristas = () => {
    //window.open("http://localhost:6102/baristas", '_blank').focus();
    window.open("https://staging-rewrite.somosprotagonistas.com.ar/baristas", '_blank').focus();
    //window.open("https://somosprotagonistas.com.ar/baristas", '_blank').focus();
  }

  const handleLogout = () => {
    history.push("/");
    logout();
  }


  return (
    <>
    <DrawerStyled
        anchor={"left"}
        open={visibilityMenuMobile !== "hide" ? true : false}
        onClose={() => setVisibilityMenuMobile("hide")}
    >
      <div className="inner_drawer">
        <div className="user_drawer">
          {userData &&
            <>
            <ImageRound className="image_round" css_styles={{width: 80, height: 80}} file={userData?.media} onClick={() => handleShowMenu('/perfil')}>
              {userData && userData.media?.length ?
                <img src={userData.media} />
              :
                <img src="/images/noavatar.jpg" />
              }
            </ImageRound>
            <div className="user_info">
              <p className="name">{userData.name}</p>
              <p className="role">{userData.role}</p>
              <p className="eess">{userData.cadena}</p>
            </div>
            </>
          }
        </div>

        <ul>
          <li className={`${location.pathname === "/" ? "selected" : ""}`} onClick={() => handleShowMenu('/')}>
            <div className="ico">
              <BsMegaphone />
            </div>
            <div className="text">
              Muro
            </div>
          </li>
          <li className={`${location.pathname === "/campus" ? "selected" : ""}`} onClick={() => handleShowMenu('/campus')}>
            <div className="ico">
              <PiBookOpenText />
            </div>
            <div className="text">
              Campus
            </div>
          </li>
          <li className={`${location.pathname === "/catalogo" ? "selected" : ""}`} onClick={() => handleShowMenu('/catalogo')}>
            <div className="ico">
              <BsListUl />
            </div>
            <div className="text">
              Catálogo
            </div>
          </li>
          {/* {dnis_seguridad_2024.includes(userData?.dni) ?
          <li className={`${location.pathname === "/semana-de-la-seguridad" ? "selected" : ""}`} onClick={() => handleShowMenu('/semana-de-la-seguridad')}>
            <div className="ico">
              <IoExtensionPuzzleOutline />
            </div>
            <div className="text">
              Trivia Seguridad
            </div>
          </li> : ""} */}
          {/* <li className={`${location.pathname === "/trivia" ? "selected" : ""}`} onClick={() => handleShowMenu('/trivia')}>
            <div className="ico">
              <IoExtensionPuzzleOutline />
            </div>
            <div className="text">
              Trivia
            </div>
          </li> */}

          {userData && incentivoTiendas(userData?.pos) &&
          <li className={`${location.pathname === "/triplica-box" ? "selected" : ""}`} onClick={() => handleShowMenu('/triplica-box')}>
            <div className="ico meta">
              <MetaIconSVG fill={"rgb(109, 105, 239)"} className={""} /> 
            </div>
            <div className="text">
              Triplica Box
            </div>
          </li> }

          {/* {show_baristas.includes(userData?.dni) ? */}
          
          <li
            className={`baristas ${location.pathname === '/baristas' ? 'selected' : ''}`}
            onClick={() => handleGoToBaristas()/*goRoute("baristas")*/}
          >
            <div className='icon'>
              <BaristaIcon fill={"rgb(109, 105, 239)"} className={""} /> 
            </div>
            <p>Copa Barista</p>
            <div className='line_selected'></div>
          </li> 
        
          {/* : ""} */}

          {/* {userData?.role === 'Empresario / Gerente' || userData?.role === 'District Manager' || userData?.role === 'Territory Manager' ?
          <li className={`${location.pathname === "/catalogo" ? "selected" : ""}`} onClick={() => window.open("https://prod.dotspanel.com", '_blank').focus()}>
            <div className="ico">
              <LuFolderUp />
            </div>
            <div className="text">
              Panel
            </div>
          </li> : ""} */}

          {/* <li className={`${location.pathname === "/shellbox" ? "selected" : ""}`} onClick={() => handleShowMenu('/shellbox')}>
            <div className="ico">
              <BsBox />
            </div>
            <div className="text">
              ShellBox
            </div>
          </li>


          <li className={`${location.pathname === "/trivia" ? "selected" : ""}`} onClick={() => handleShowMenu('/trivia')}>
            <div className="ico">
              <IoExtensionPuzzleOutline />
            </div>
            <div className="text">
              Trivia
            </div>
          </li>


          <li onClick={() => { handleOpenContact(); handleShowMenu() } }>
            <div className="ico">
              <BiMessageDetail />
            </div>
            <div className="text">
              Contacto
            </div>
          </li>
          <li className={`${location.pathname === "/perfil" ? "selected" : ""}`} onClick={() => handleShowMenu('/perfil')}>
            <div className="ico">
              <BiUser />
            </div>
            <div className="text">
              Mi Perfíl
            </div>
          </li> */}

          <li className="bt_salir" onClick={() => handleLogout()}>
            <div className="ico">
              <BiLogOutCircle />
            </div>
            <div className="text">
              Salir
            </div>
          </li>
        </ul>

      </div>
    </DrawerStyled>

    </>
  );
}


export default MenuMobile;
